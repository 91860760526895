<template>
    <div class="main">
        <div class="box">
            <h1 class="title-about" v-html="$t('mission.title')"></h1>
            <p v-html="$t('mission.text')"></p>
            <!-- ITEM 1 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-1.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.one.title')"></h2>
                    <p v-html="$t('mission.one.text1')"></p>
                    <p v-html="$t('mission.one.text2')"></p>
                </div>
            </div>
            <!-- ITEM 2 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-2.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.two.title')"></h2>
                    <p v-html="$t('mission.two.text1')"></p>
                    <p v-html="$t('mission.two.text2')"></p>
                </div>
            </div>
            <!-- ITEM 3 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-3.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.three.title')"></h2>
                    <p v-html="$t('mission.three.text1')"></p>
                </div>
            </div>
            <!-- ITEM 4 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-4.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.four.title')"></h2>
                    <p v-html="$t('mission.four.text1')"></p>
                    <p v-html="$t('mission.four.text2')"></p>
                    <p v-html="$t('mission.four.text3')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "Mission",
}
</script>

<style lang="scss" scoped>
    p {
        font-size:18px;
    }

    .mission-item {
        display: flex;
        align-items: center;
        margin:80px 0;
    }

    .mission-img {
        width: 90%;
        max-width: 200px;
    }

    .mission-item h2 {
        color:$mainColor;
        text-align: left;
        text-transform: uppercase;
        font-size:25px;
        font-weight: 400;
        margin-bottom:40px;
    }

    .col-25 {
        display: flex;
        justify-content: center;
    }

    .col-75 {
        border-left: 3px solid $mainColor;
        padding-left:20px;
    }

    @media screen and (max-width:768px){
        .mission-item {
            flex-direction: column;
            margin: 60px 0;
        }

        .mission-img {
            margin-bottom: 20px;
        }

        .col-75 {
            padding:0;
            border:none;
        }

        p {
            text-align: justify;
        }
    }
</style>